import React, { useState, useEffect } from "react";
import request from "superagent";
import logo1 from "../assets/image/Reload.svg";
import icon from "../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon4 from "../assets/Facesense_LandingPage_Img/Cancel.svg";
import "./HomeFrom.css";
import { BaseURL } from "../BaseURL";

const HomeForm = () => {
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };

  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });

  const [captchaNum1, setCaptchaNum1] = useState(0);
  const [captchaNum2, setCaptchaNum2] = useState(0);
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptchaNum1(num1);
    setCaptchaNum2(num2);
    setCaptchaAnswer("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCaptchaChange = (event) => {
    setCaptchaAnswer(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !formValues.First_Name ||
      !formValues.Email ||
      !formValues.Phone_Number
    ) {
      alert("Please fill out required fields.");
      return;
    }

    if (parseInt(captchaAnswer, 10) === captchaNum1 + captchaNum2) {
      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/ContactForm/Contact`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
      setShowPopup(true);
      generateCaptcha();
    } else {
      alert("Captcha verification failed. Please try again.");
      generateCaptcha();
    }
  };
  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleReloadCaptcha = () => {
    generateCaptcha();
  };

  return (
    <div className="Formcontainer">
      <div className="Form">
        <div className="Left_Section">
          <h1>
            Tell us about your requirements, we’ll be happy to connect with you.
          </h1>
        </div>
        <div className="Rightsection">
          <div className="Input_Holder">
            <input
              type="text"
              name="First_Name"
              className="Input_Field"
              placeholder="First Name *"
              value={formValues.First_Name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="Last_Name"
              className="Input_Field2"
              placeholder="Last Name"
              value={formValues.Last_Name}
              onChange={handleChange}
            />
          </div>
          <div className="Input_Holder">
            <input
              type="email"
              name="Email"
              className="Input_Field"
              placeholder="Email *"
              value={formValues.Email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="Phone_Number"
              className="Input_Field2"
              placeholder="Phone Number *"
              value={formValues.Phone_Number}
              onChange={(event) => {
                handleChange(event);
                handleNumChangeM(event);
              }}
              required
            />
          </div>
          <textarea
            name="Message"
            className="Message"
            placeholder="Message"
            value={formValues.Message}
            onChange={handleChange}
          />

          <div className="Captcha_Block">
            <div className="numbers">
              {captchaNum1} <label>+</label> {captchaNum2} <label>=</label>
            </div>
            <input
              className="Input_Field_1"
              type="number"
              value={captchaAnswer}
              onChange={handleCaptchaChange}
            />
            <img
              className="Re"
              src={logo1}
              onClick={handleReloadCaptcha}
              alt="Reload"
            />
            <button className="Form_Submit" onClick={handleSubmit}>
              Submit
            </button>
          </div>
          {showPopup && (
            <>
              <div className="Overlay" />
              <div className="Popup">
                <img
                  src={icon4}
                  alt="Close"
                  className="CloseButton"
                  onClick={() => setShowPopup(false)}
                />
                <img src={icon} alt="Popup" />
                <span>
                  <h1>Thank You</h1>

                  <h3>
                    <p>The form was submitted successfully</p>
                  </h3>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeForm;
