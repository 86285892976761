import "./Ezparking.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import logo1 from "../../assets/image/ezparking_logo.svg";
import logo2 from "../../assets/image/ezparking_banner.jpg";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import Header from "../../Header/Header";
import Productspage from "../../Productspage/Productspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const Ezparking = () => {
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [formValues.First_Name,
      formValues.Last_Name,
      formValues.Email,
      formValues.Phone_Number,
      formValues.Message,].some((field) => field?.trim() !== "")
    ) {
    setForm(false)
    setShowPopup(true);

    formValues.windowTitle = document.title;
    request
      .post(`${BaseURL}/Mail/EZparking`)
      .send(formValues)
      .set("Accept", "application/json")
      .then((response) => {
        console.log(response.body.message);
      })
      .catch((error) => {
        console.error("Error sending Email:", error);
      });

    setFormValues({
      First_Name: "",
      Last_Name: "",
      Email: "",
      Phone_Number: "",
      Message: "",
    });}
  };

  return (
    <div>
      <Helmet>
        <title>
          Smart Automated Parking Management System Solutions- EZparking
        </title>
        <meta
          name="description"
          content="Experience hassle-free parking management with our AI based parking management platform that gives makes your parking management easy and paperless. ANPR, Video analytics and App based solution, EZ Parking helps you automate every process of parking system with parking guidance system for users.
          "
        />
        <meta
          name="keyword"
          content=" Automated parking system, Parking system in India, Automated parking in India, Multi-level parking in India, Paperless parking management in India, Automated parking companies in India, Parking management service in India, parking management, car parking management, parking space management, parking service management, parking control management.
          "
        />
        <link rel="canonical" href="https://www.safepro.tech/ezparking" />
      </Helmet>
      <Header />
      <Productspage />
      <section className="Sec">
        <div className="Body_Section">
          <div className="Body_Section_parts">
            <div className="left_Body_Section_parts">
              <img src={logo1} alt="" />
              <p>Smart, Paperless & Easy Parking solution</p>
            </div>
            <div className="right_Body_Section_parts">
              <img src={logo2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <div className="section_2">
        <div className="section_2_Body_Section">
          <div className="section_2_width">
            <div className="section_2_content">
              <h1>EZParking</h1>
              <p>
                EZParking from Safepro uses artificial intelligence to automate
                your parking space with ease. As the name suggests, parking must
                be easy and not complicated. When banking, bill payments,
                education, transport, shopping and many more sectors have gone
                paperless, why should parking management be on the age-old
                technology of paper-based parking ticket? Explore our EZ Parking
                management system to manage your parking space better and
                efficiently without the hassles of managing it. It works on our
                Opticsense neural compute platform synchronizing with ANPR
                camera, edge video analytics, mobile apps to make parking a
                breeze. Combining it with our AI excellence award winning
                managed services, we design, build and operate your parking
                space with our EZParking product, letting you focus on core
                works you specialise in. Effortless, easy and paperless parking
                management services from Safepro is a state-of-the-art AI based
                automation solution to make your parking space effective,
                productive & decisive.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_left">
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>
                    Artificial intelligence enabled parking management system
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Powered with ANPR edge analytics cameras</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Integration with boom barriers and automated bollards</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Automated number plate recognition, Vehicle type detection,
                    Vehicle counting
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>ANPR integrated APP based parking system</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>App, QR, AR based payment gateway for easier payments</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Optional integration with drive face recognition capture
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Parking bay availability notification on premises and apps
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Centralised dashboard with data analytics for holistic view
                    of parking management
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Wealth of EIS & MIS reports for as an affirmative decision
                    support system
                  </p>
                </div>
              </div>
            </div>
            <div className="mobile_section_3_right"></div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog} >
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e)
                            handleNumChangeM(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "80px" }}
        >
          <button
            className="Body_Content_Forth_Button_One"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default Ezparking;
