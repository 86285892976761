import "./Thermography.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Header/Header";
import EProductspage from "../../ExtraProductpage/EProductspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import image from "../../assets/image/video_ana_thermography.jpg";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const Thermography = () => {
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [formValues.First_Name,
      formValues.Last_Name,
      formValues.Email,
      formValues.Phone_Number,
      formValues.Message,].some((field) => field?.trim() !== "")
    ) {
      setForm(false)
      setShowPopup(true);

      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/Mail/Thermography`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>
          Thermographic Camera | Security Camera with Temperature Sensor
        </title>
        <meta
          name="description"
          content="Safepro provides temperature tracking camera with thermal and dual sensor cameras for detecting human temperature and fever screening of people meant for offices, shopping malls, government offices, railway stations, airports, tourist places.
          "
        />
        <meta
          name="keyword"
          content="temperature tracking camera, thermal camera, infrared camera, thermographic camera, camera with temperature sensor, security camera with temperature sensor, fever screening for Covid-19
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/temperature-tracking-camera/covid-19/thermography.html"
        />
      </Helmet>
      <Header />
      <EProductspage />
      <section>
        <div className="Time_Attendance">
          <div className="first_Section">
            <div className="Left">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="Left_Mobile">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="right">
              <h1>Thermography</h1>
              <a href="/temperature-tracking-camera/covid-19/thermography.html">
                Contactless temperature tracking with face detection
              </a>
              <p>
                The world today lives in unprecedented times with threat of
                virus hitting us all hard on our daily lives. Opticsense®
                Thermography application incorporates the best of thermal video
                analytics to detect the human face and the temperature. It also
                comes with an automated notification to alert people with higher
                temperature higher detecting Covid-19. Safepro thermal body
                temperature screening system is equipped with AI based human
                face detection coupled with instant temperature capture from the
                face with high accuracy.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_content">
              <h4 className="Text">
                It is also equipped to screen multiple people at a time ensuring
                social distance along with alarm notifying detection of person
                with high temperature. Using our Opticsense® neural compute
                platform and Facesense™ face recognition solutions, our
                thermography gives the industry’s best accuracy for temperature
                detection.
              </h4>
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>
                    Thermal & visual integrated camera with human face detection
                    AI
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Contactless temperature measurement for 20-30people at a
                    time
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Dashboard to monitor and track high temperatures</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Instant alarm for higher temperature screened</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Higher accuracy at +0.3 oC</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Detection at 3 - 5mtrs distance</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Automated PDF reports of people with high temperature</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Optional integration with SMS for reports</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Plug & play use with quicker deployment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog}>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e)
                            handleNumChangeM(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "60px" }}
        >
          <button
            className="Body_Content_Forth_Button_One"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default Thermography;
