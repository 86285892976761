import "./DriverAlert.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Header/Header";
import EProductspage from "../../ExtraProductpage/EProductspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import image from "../../assets/image/video_ana_driver_alert_system.jpg";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const DriverAlert = () => {
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [formValues.First_Name,
      formValues.Last_Name,
      formValues.Email,
      formValues.Phone_Number,
      formValues.Message,].some((field) => field?.trim() !== "")
    ) {
      setForm(false)
      setShowPopup(true);

      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/Mail/Drive`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>
          Public Transportation Surveillance Systems in Bangalore India -
          Safepro
        </title>
        <meta
          name="description"
          content="Security & safety for public transport buses and railways, trains, metros, cabs & autos in the city. City transport safety ensured with Safepro onboard CCTV for buses & trains.
          "
        />
        <meta
          name="keyword"
          content="Public Transportation Surveillance Systems, CCTV for buses, CCTV for trains, CCTV for metro, Public Transportation Surveillance in India, Bus Surveillance camera System, Bus CCTV Camera, Bangalore, Safepro.
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/videosurveillance/publictransport.html"
        />
      </Helmet>
      <Header />
      <EProductspage />
      <section>
        <div className="Time_Attendance">
          <div className="first_Section">
            <div className="Left">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="Left_Mobile">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="right">
              <h1>Driver Alert System</h1>
              <a href="/videosurveillance/publictransport.html">
                Feel safe with safepro onboard security
              </a>
              <p>
                Use of public transport for a good city infrastructure is very
                essential and to do this making commuters feel safe in these
                public transport systems is absolutely necessary. Whether it’s
                metros or buses or cabs, we have a whole range of solutions to
                cater to this sector from mega pixel surveillance cameras to
                onboard rugged NVRs, automotive compliant cabling, intercom
                systems and central command control solutions.For Governments to
                make public transport as a favorable mode of commuting and to
                decongest the already congested city roads, it’s imperative to
                make public transport safer for people to travel.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_content">
              <h4 className="Text">
                Facial detection, emergency alerts, real-time command control
                center connectivity, day & night surveillance, rugged onboard
                storage to take the bad roads & raw power from batteries,
                unified wireless connectivity to bring all the buses plying on
                the roads to central command center, 3G/4G connectivity for
                authorities to be alerted of incidents on their smart
                Phone_Numbers as it happens are few of them we offer as onboard
                surveillance solutions to public transport systems. Surveillance
                & beyond Video connectivity to nearing bus stops, integrated
                ticketing system, real-time bus route tracking are few of the
                value adds we can design & build for your public transport
                systems.
              </h4>
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>Cognitive 4k high definition video surveillance cameras</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Ruggedised onboard surveillance storage</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Face detection/recognition</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Emergency response system to central command center</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Inbuilt intercom 2way audio connectivity to central command
                    center
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Video auto-dump facility at depots and bus stands</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Encrypted AutoMesh network for unified platform for all the
                    buses in the city
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Connectivity with smart city devices within the network</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog}>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e)
                            handleNumChangeM(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "60px" }}
        >
          <button
            className="Body_Content_Forth_Button_One"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default DriverAlert;
