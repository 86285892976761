import "./Admetrics.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Header/Header";
import EProductspage from "../../ExtraProductpage/EProductspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import image from "../../assets/image/video_ana_Admetrics.jpg";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const Admetrics = () => {
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [formValues.First_Name,
      formValues.Last_Name,
      formValues.Email,
      formValues.Phone_Number,
      formValues.Message,].some((field) => field?.trim() !== "")
    ) {
      setForm(false)
      setShowPopup(true);
      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/Mail/Admetrics`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>
          Tracking System you OOH Advertisement Billboard- Admetrics
        </title>
        <meta
          name="description"
          content="Admetrics lets you measure your billboard ads precisely and effectively giving you the total count of people seen ads on your billboard, demographics and more to ensure that your customers get the complete benefit of advertising at the right place with the right content to the right audience.Safepro offers best CCTV Camera with face recognition technology, time attendance management system, encrypted data & video security & much more applications face recognition scan, employee time clock biometric.
          "
        />
        <meta
          name="keyword"
          content="Advertising measurement, Billboard Advertising measurement, Advertising, Billboards, Advertising on Billboards, Billboard advertising, Video analytics for advertising TRP, Track your ads TRP
          "
        />
        <link rel="canonical" href="https://www.safepro.tech/admetrics" />
      </Helmet>
      <Header />
      <EProductspage />
      <section>
        <div className="Time_Attendance">
          <div className="first_Section">
            <div className="Left">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="Left_Mobile">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="right">
              <h1>Admetrics</h1>
              <a href="/face-recognition-time-attendance-System.php">
                Your AI based ad measurement tool for your billboards
              </a>
              <p>
                Something which is not measurable isn’t accountable, & Things
                that aren’t accountable isn’t profitable. Let’s make your ads
                measurable with Safepro Admetrics designed & built on AI based
                automated ads viewed tracker. With millions of content streaming
                on billboards, no one knows the performance of each
                advertisement or content that’s streaming on billboards. Every
                measure today is based on certain assumptions not a real-time
                tracker.A retrofit device for your existing billboards,
                Admetrics easily automates your metrics for ads performance in
                that billboard giving you the detailed analytics about number of
                people that’s viewed the ads on your billboards.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_content">
              <h4 className="Text">
                Our Opticsense® neural compute platform is your ads watchdog
                that gives you a plethora of data of people who has seen your
                ads including demographics which will help you position your ads
                better, giving your advertisers complete customer satisfaction
                and value for money for their ad spends. Track, identify and
                measure audience and viewership of your billboard ads easily
                with Admetrics, an AI vision computing tool that will give
                precise information about your audience such as – nos of people
                viewed the ads, gender, age group, peak and off-peak viewership.
                This will be an incredible tool to inform your customers about
                the performance of their ads with plethora of analytical reports
                of each ad streamed on your billboards. With a unified
                dashboard, you can integrate all your billboards on to a unified
                platform for performance tracking
              </h4>
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>
                    Real-time tracker on number of people viewed your ads on
                    billboard
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Data analytics on male and female viewers of ads</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Age group of people who has viewed the ads</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Data analytics reports of performance of the ads on the
                    specific billboard
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Manage all billboards irrespective of geography on a unified
                    dashboard
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    API stack to integrate with your ads streamer based on the
                    data analytics
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Retrofit product making it easier to integrate with your
                    existing billboards
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Automated reports for decision makers about the metrics of
                    each billboard
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog}>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e)
                            handleNumChangeM(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "60px" }}
        >
          <button
            className="Body_Content_Forth_Button_One"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default Admetrics;
