import "./FaceAttendance.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Header/Header";
import EProductspage from "../../ExtraProductpage/EProductspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import image from "../../assets/image/Time_Attendance_banner.jpg";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const FaceAttendance = () => {
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [formValues.First_Name,
      formValues.Last_Name,
      formValues.Email,
      formValues.Phone_Number,
      formValues.Message,].some((field) => field?.trim() !== "")
    ) {
      setForm(false)
      setShowPopup(true);

      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/Mail/Catalog`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>
          Best Face Recognition Time Attendance System | Employee Time Clock
          Biometric System
        </title>
        <meta
          name="description"
          content="Safepro offers best CCTV Camera with face recognition technology, time attendance management system, encrypted data & video security & much more applications face recognition scan, employee time clock biometric.
          "
        />
        <meta
          name="keyword"
          content="face recognition, face recognition technology, facial recognition system, face recognition camera, face recognition CCTV, Facial Recognition Technology System, Face Recognition CCTV Camera, time attendance system, attendance system, time and attendance systems, employee attendance system, time attendance terminal, time clock systems, employee time clock, face recognition time attendance system, time attendance management system, time and attendance machine.  
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/face-recognition-time-attendance-System.php"
        />
      </Helmet>
      <Header />
      <EProductspage />
      <section>
        <div className="Time_Attendance">
          <div className="first_Section">
            <div className="Left">
              <img src={image} alt="Face Recognition Time & Attendance" />
            </div>
            <div className="Left_Mobile">
              <img src={image} alt="Face Recognition Time & Attendancee" />
            </div>
            <div className="right">
              <h1>Face Recognition Time & Attendance</h1>
              <a href="/face-recognition-time-attendance-System.php">
                Oops! Still on Biometric?
              </a>
              <p>
                Facesense - Face Recognition System (FRS) is an enterprise scale
                facial detection & recognition scanning system. Built on our
                patented Opticsense® Neural Compute Platform, it delivers the
                most accurate detection and matching in the industry for
                attendance management system. Facesense - Face Recognition
                System (FRS) is an enterprise scale facial detection &
                recognition scanning system. Built on our patented Opticsense®
                Neural Compute Platform, it delivers the most accurate detection
                and matching in the industry for attendance management system.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_content">
              <h4 className="Text">
                From network cameras, video analytics, smart poles to IP
                speakers, IP intercoms, unmanned ground vehicles, we make it
                all. With a state-of-the-art AI lab and a strong research and
                development team, all products are designed and developed in
                India adhering to global quality standards.
              </h4>
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>
                    Use your existing IP cameras for your attendance management
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Just walk as your naturally do into your office or class,
                    attendance is marked
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Noninvasive without the need to stand & wait to face a
                    device
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Plug & play face recognition attendance management system
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Can Recognise multiple people in one single frame</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Face recognised & attendance marked in a second</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Mobile App for employees to track their attendance reports
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Multiple offices on one unified attendance tracker dashboard
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Pay-as-you-go subscription with dedicated cloud & on-premise
                    hybrid model
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    API stack for integration with your existing HR or ERP
                    applications
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Mobile App attendance punch feature for employees on the
                    move with location tracking
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Email and App notifications with plethora of attendance
                    reports and data analytics
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog}>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e)
                            handleNumChangeM(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "60px" }}
        >
          <button
            className="Body_Content_Forth_Button_One"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default FaceAttendance;
