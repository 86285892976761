import "./Object.css";
import Header from "../../Header/Header";
import EProductspage from "../../ExtraProductpage/EProductspage";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import image from "../../assets/image/video_ana_Object_detection.jpg";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const ObjectDetection = () => {
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [formValues.First_Name,
      formValues.Last_Name,
      formValues.Email,
      formValues.Phone_Number,
      formValues.Message,].some((field) => field?.trim() !== "")
    ) {
      setForm(false)
      setShowPopup(true);

      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/Mail/ObjectDetection`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>
          Object Detection and Counting | Object Recognition Techniques
        </title>
        <meta
          name="description"
          content="Safepro offer opticsense object detection video analytics enables the cameras in detecting and counting objects within its vicinity, recognition techniques simple objects like Phone_Numbers, laptops, couches, vases etc.
          "
        />
        <meta
          name="keyword"
          content="Objection Detection, Objection Recognition, Object Counting, Object detection techniques.
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/videoanalytics/object-detection.html"
        />
      </Helmet>
      <Header />
      <EProductspage />
      <section>
        <div className="Time_Attendance">
          <div className="first_Section">
            <div className="Left">
              <img src={image} alt="Object Detection and Counting" />
            </div>
            <div className="Left_Mobile">
              <img src={image} alt="Object Detection and Counting" />
            </div>
            <div className="right">
              <h1>Object Detection & Counting</h1>
              <a href="/videoanalytics/ppedetection/pharma.html">
                Make Counting Ease
              </a>
              <p>
                Any object, any size, any color, our vision systems are trained
                to detect and action with user defined actioning on object
                detection. Part of our process automation product, object
                counting video analytics is one of our intelligent neural
                compute products that can automate any of our counting process
                easily. Whether its box, tyres, bottles, bags, etc., to be
                counted on your conveyor belts or warehouse or factories, we can
                do it all. Identify the object and combining it with our
                counting video analytics gives you the most accurate automated
                counting system with no human interference.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_content">
              <h4 className="Text">
                With over one million object detection capability, Opticsense®
                object detection video analytics enables the cameras in
                detecting objects within its vicinity. From identifying simple
                objects like Phone_Numbers, laptops, couches, vases, it can
                clearly distinguish between humans, vehicles and animals too.
                This video analytics combined with our various other video
                analytics like scene detection, object counting or perimeter
                intrusion detection can be a very important tool for security
                and process automation solutions. You’ve got an object that
                requires recognition and counting, tracking pilferage and
                enhancing operational performance in your office or factory or
                retail outlets, we can do it for you with ease. Object
                recognition including three dimensional measurements, accurate
                counting with integrated dashboard, giving you the analytical
                reports on your desk or app without human interference.
              </h4>
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>Multiple object identification</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Object counting with precision monitoring</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Accurate counting with visual evidence</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog}>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e)
                            handleNumChangeM(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "60px" }}
        >
          <button
            className="Body_Content_Forth_Button_One"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default ObjectDetection;
