import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import request from "superagent";
import "../Facesense.css";
import Header from "../../../Header/Header";
import Formpage from "../../../Form/Form";
import Footer from "../../../Footer/Footer";
import image1 from "../../../assets/Facesense_LandingPage_Img/Bullet_Img.svg";
import image2 from "../../../assets/Facesense_LandingPage_Img/FaceSenseBannerImg.svg";
import icon1 from "../../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon2 from "../../../assets/Facesense_LandingPage_Img/CancelIcon.svg";
import icon3 from "../../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import icon6 from "../../../assets/Facesense_LandingPage_Img/Webpage.svg";
import icon14 from "../../../assets/Facesense_LandingPage_Img/Pay.svg";
import icon15 from "../../../assets/Facesense_LandingPage_Img/Install.svg";
import icon16 from "../../../assets/Facesense_LandingPage_Img/Done.svg";
import icon7 from "../../../assets/Facesense_LandingPage_Img/OfficeBuildingIcon.svg";
import icon8 from "../../../assets/Facesense_LandingPage_Img/Industries.svg";
import icon9 from "../../../assets/Facesense_LandingPage_Img/Store.svg";
import icon10 from "../../../assets/Facesense_LandingPage_Img/GovernmentJobs.svg";
import icon11 from "../../../assets/Facesense_LandingPage_Img/Hospital.svg";
import icon12 from "../../../assets/Facesense_LandingPage_Img/College.svg";
import icon13 from "../../../assets/Facesense_LandingPage_Img/facesense_face_image.svg";
import { BaseURL } from "../../../BaseURL";

const FaceCoimbatore = () => {
  const [showDemoform, setDemoform] = useState(false);
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleCatalog = (event) => {
    setShowPopup(true);
    event.preventDefault();

    formValues.windowTitle = document.title;
    request
      .post(`${BaseURL}/Mail/Catalog`)
      .send(formValues)
      .set("Accept", "application/json")
      .then((response) => {
        console.log(response.body.message);
      })
      .catch((error) => {
        console.error("Error sending Email:", error);
      });

    setFormValues({
      First_Name: "",
      Last_Name: "",
      Email: "",
      Phone_Number: "",
      Message: "",
    });
  };
  const handleDemo = (event) => {
    setShowPopup1(true);
    event.preventDefault();

    formValues.windowTitle = document.title;
    request
      .post(`${BaseURL}Mail/Demo`)
      .send(formValues)
      .set("Accept", "application/json")
      .then((response) => {
        console.log(response.body.message);
      })
      .catch((error) => {
        console.error("Error sending Email:", error);
      });

    setFormValues({
      First_Name: "",
      Last_Name: "",
      Email: "",
      Phone_Number: "",
      Message: "",
    });
  };
  useEffect(() => {
    const animateOnScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
        } else {
          entry.target.classList.remove("active");
        }
      });
    };
    const Panels = document.querySelectorAll(
      ".Body_Fifth_Anime_Box_TB, .Body_Fifth_Anime_Box_BT"
    );
    const observer = new IntersectionObserver(animateOnScroll, {
      root: null,
      threshold: 0.2,
    });

    Panels.forEach((Panel) => {
      observer.observe(Panel);
    });
  }, []);

  return (
    <div className="Container">
      <Helmet>
        <title>
          Face Recognition Camera in Coimbatore | Time Attendance System |
          Employee Time Clock Biometric System
        </title>
        <meta
          name="description"
          content="Safepro offers best CCTV Camera with face recognition technology Coimbatore, cognitive surveillance, access control,  time attendance management system, encrypted data & video security & much more applications face recognition scan, employee time clock biometric.
          "
        />
        <meta
          name="keyword"
          content=" face recognition in Coimbatore, face recognition technology in Coimbatore, Coimbatore, facial recognition system in Coimbatore, face recognition camera in Coimbatore, face recognition CCTV in Coimbatore, Facial Recognition Technology System Coimbatore, Face Recognition CCTV Camera in Coimbatore, time attendance system in Coimbatore, attendance system in Coimbatore, time and attendance systems in Coimbatore, employee attendance system in Coimbatore, time attendance terminal in Coimbatore, time clock systems in Coimbatore, employee time clock in Coimbatore, face recognition time attendance system in Coimbatore, time attendance management system in Coimbatore, time and attendance machine in Coimbatore.  
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/face-recognition-time-attendance-System-Coimbatore/"
        />
      </Helmet>
      <Header />
      <section>
        <div className="Text_Container">
          <div className="Top_Section_Content">
            <h1>
              A <span> Face Recognition </span> based Time and Attendance
              Management System for Coimbatore
            </h1>
          </div>
        </div>
      </section>
      <div className="Video_Container">
        <div className="Left_Sec">
          <img className="Facesense_Image" src={icon13} alt="Facesense" />
          <h1>
            <p className="Title">Your Face is your ID.</p>
            <p className="SubTitle">
              Attendance management on Face recognition
            </p>
          </h1>
        </div>
        <iframe
          title="Facesense"
          src="https://www.youtube.com/embed/9-knoujstiI?si=L6bXCBwUBE80FpCZ"
        ></iframe>
      </div>
      <section id="Section">
        <div className="Body_Container">
          <div className="Body_Content">
            <h2>Facesense</h2>
            <p>
              Welcome to Safepro Facesense, a face recognition based time and
              attendance management system built on deep learning neural compute
              platform. Use your existing IP cameras with our Facesense
              technology, enabling it to capture attendance in your office,
              school, or college effortlessly. Facesense is your plug & play
              system, noninvasive, touchless, retrofit product converting your
              existing IP cameras into state-of-the-art time & attendance
              management tool. From single walkthrough to multiple walking into
              your premises, Facesense can handle this with ease.
            </p>
            <p>
              Combination of desktop, cloud and mobile apps makes it perfect
              inclusive attendance system to integrating multiple offices,
              campuses, remote and temporary sites on a single unified platform.
              This being a retrofit device, integrate Facesense with your
              existing HR or ERP applications with our easy and simple API
              stack.
            </p>
          </div>
        </div>
      </section>
      <section id="Section">
        <div className="Body_Container">
          <div className="Body_Content_Second">
            <div className="Content">
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Use your existing IP cameras for your attendance management
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Just walk as your naturally do into your office or class,
                    attendance is marked
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Non-invasive without the need to stand & wait to face a
                    device
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Plug & play face recognition attendance management system
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>Can Recognize multiple people in one single frame</p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>Face recognised & attendance marked in a second</p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Mobile App for employees to track their attendance reports
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Multiple offices on one unified attendance tracker dashboard
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Pay-as-you-go subscription with dedicated cloud & on-premise
                    hybrid model
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    API stack for integration with your existing HR or ERP
                    applications
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Mobile App attendance punch feature for employees on the
                    move with location tracking
                  </p>
                </div>
              </div>
              <div className="Table">
                <div className="BuletImage">
                  <img alt="" srcSet={image1} />
                </div>
                <div className="Heading">
                  <p>
                    Email and App notifications with plethora of attendance
                    reports and data analytics
                  </p>
                </div>
              </div>
            </div>
            <div className="Images_Container">
              <img alt="" srcSet={image2} />
            </div>
          </div>
        </div>
      </section>
      <section id="Section">
        <div className="Body_Container">
          <div className="Body_Content_Forth">
            <div className="Body_Content_Forth_Inner">
              <div className="Container_One">
                <div className="Headline">
                  <h3>Features</h3>
                </div>
                <div className="Headline_Inner">
                  <p>Retro-fit, use your existing IP cameras</p>
                </div>
                <div className="Headline_Inner">
                  <p>
                    100% accuracy even with not having to pause and focus on the
                    device
                  </p>
                </div>
                <div className="Headline_Inner">
                  <p>Recognises multiple people in single frame</p>
                </div>
                <div className="Headline_Inner">
                  <p>
                    Tech know-how, requires dedicated specialist to configure &
                    maintain
                  </p>
                </div>
                <div className="Headline_Inner">
                  <p>Retro-fit, use your existing IP cameras</p>
                </div>
                <div className="Headline_Inner">
                  <p>
                    100% accuracy even with not having to pause and focus on the
                    device
                  </p>
                </div>
                <div className="Headline_Inner">
                  <p>Recognises multiple people in single frame</p>
                </div>
                <div className="Headline_Inner">
                  <p>
                    Tech know-how, requires dedicated specialist to configure &
                    maintain
                  </p>
                </div>
              </div>
              <div className="Container_One_Inner">
                <div className="Container_Two">
                  <div className="Headline">
                    <h3>Facesence</h3>
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                </div>
                <div className="Container_Three">
                  <div className="Headline">
                    <h3>Biometrics</h3>
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                </div>
                <div className="Container_Four">
                  <div className="Headline">
                    <h3>Face recognition tabs</h3>
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon2} />
                  </div>
                  <div className="Headline_Inner_Img">
                    <img alt="" srcSet={icon1} />
                  </div>
                </div>
              </div>
            </div>
            <div className="Body_Content_Forth_Inner_Buttons">
              <button
                className="Body_Content_Forth_Button_One"
                onClick={() => {
                  setForm(true);
                }}
              >
                <strong>Product catalog</strong> &nbsp;
                <img alt="" srcSet={icon3} />
              </button>
              <button
                className="Body_Content_Forth_Button_Two"
                onClick={() => {
                  setDemoform(true);
                }}
              >
                <strong>Click for demo</strong>
              </button>
            </div>

            {showPopup && (
              <>
                <div className="Overlay" />
                <div className="Popup">
                  <img
                    src={icon4}
                    alt="Close"
                    className="CloseButton"
                    onClick={() => setShowPopup(false)}
                  />
                  <img src={icon1} alt="Popup" />
                  <span>
                    <h1>Thank You</h1>

                    <h3>
                      <p>The form was submitted successfully</p>
                      <p>You will receive a catalog on your email</p>
                    </h3>
                  </span>
                </div>
              </>
            )}

            {showPopup1 && (
              <>
                <div className="Overlay" />
                <div className="Popup">
                  <img
                    src={icon4}
                    alt="Close"
                    className="CloseButton"
                    onClick={() => setShowPopup1(false)}
                  />
                  <img src={icon1} alt="Popup" />
                  <span>
                    <h1>Thank You</h1>

                    <h3>
                      <p>Our Team will get in touch with you very soon!</p>
                    </h3>
                  </span>
                </div>
              </>
            )}

            {showForm && (
              <>
                <div className="uc_model">
                  <div className="uc_content">
                    <div className="uc_header">
                      <p className="uc_heading_one">Please fill this form</p>
                      <span>
                        <img
                          alt=""
                          srcSet={icon4}
                          height="30px"
                          onClick={() => {
                            setForm(false);
                          }}
                        />
                      </span>
                      <p className="uc_heading_two">
                        We will share you the product catalog through your
                        email.
                      </p>
                    </div>
                    <div className="uc_body">
                      <form>
                        <div
                          className="uc-form-group"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="Field"
                            style={{ width: "45%", paddingRight: "120px" }}
                          >
                            <div className="fcf-input-group_half">
                              <input
                                type="text"
                                id="First_Name"
                                value={formValues.First_Name}
                                name="First_Name"
                                className="uc-form-control"
                                placeholder="First Name"
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="Field" style={{ width: "45%" }}>
                            <div className="fcf-input-group_half">
                              <input
                                type="text"
                                id="Last_Name"
                                value={formValues.Last_Name}
                                name="Last_Name"
                                className="uc-form-control"
                                placeholder="Last Name"
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="uc-form-group"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="Field"
                            style={{ width: "45%", paddingRight: "120px" }}
                          >
                            <div className="fcf-input-group_half">
                              <input
                                type="text"
                                id="Email"
                                value={formValues.Email}
                                name="Email"
                                className="uc-form-control"
                                placeholder="Email"
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="Field" style={{ width: "45%" }}>
                            <div className="fcf-input-group_half">
                              <input
                                type="Phone_Number"
                                id="Phone_Number"
                                value={formValues.Phone_Number}
                                name="Phone_Number"
                                className="uc-form-control"
                                placeholder="Phone number"
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uc-form-group">
                          <div className="">
                            <textarea
                              id="Message"
                              value={formValues.Message}
                              name="Message"
                              className="uc-form-control"
                              rows="6"
                              maxLength="3000"
                              placeholder="Write your message"
                              required
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                        <div className="uc-submit-button">
                          <button onClick={handleCatalog}>
                            <span>Submit</span>
                            <img alt="" srcSet={icon5} height="20px" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
            {showDemoform && (
              <>
                <div className="uc_model">
                  <div className="uc_content">
                    <div className="uc_header">
                      <p className="uc_heading_one">
                        Please fill this form for Quick Demo
                      </p>
                      <span>
                        <img
                          alt=""
                          srcSet={icon4}
                          height="30px"
                          onClick={() => {
                            setDemoform(false);
                          }}
                        />
                      </span>
                    </div>
                    <div className="uc_body">
                      <form>
                        <div
                          className="uc-form-group"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="Field"
                            style={{ width: "45%", paddingRight: "120px" }}
                          >
                            <div className="fcf-input-group_half">
                              <input
                                type="text"
                                id="First_Name"
                                value={formValues.First_Name}
                                name="First_Name"
                                className="uc-form-control"
                                placeholder="First Name"
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="Field" style={{ width: "45%" }}>
                            <div className="fcf-input-group_half">
                              <input
                                type="text"
                                id="Last_Name"
                                value={formValues.Last_Name}
                                name="Last_Name"
                                className="uc-form-control"
                                placeholder="Last Name"
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="uc-form-group"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="Field"
                            style={{ width: "45%", paddingRight: "120px" }}
                          >
                            <div className="fcf-input-group_half">
                              <input
                                type="text"
                                id="Email"
                                value={formValues.Email}
                                name="Email"
                                className="uc-form-control"
                                placeholder="Email"
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="Field" style={{ width: "45%" }}>
                            <div className="fcf-input-group_half">
                              <input
                                type="Phone_Number"
                                id="Phone_Number"
                                value={formValues.Phone_Number}
                                name="Phone_Number"
                                className="uc-form-control"
                                placeholder="Phone number"
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uc-form-group">
                          <div className="">
                            <textarea
                              id="Message"
                              value={formValues.Message}
                              name="Message"
                              className="uc-form-control"
                              rows="6"
                              maxLength="3000"
                              placeholder="Write your message"
                              required
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                        <div className="uc-submit-button">
                          <button onClick={handleDemo}>
                            <span>Submit</span>
                            <img alt="" srcSet={icon5} height="20px" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <section id="Section">
        <div className="Back_1">
          <div className="Body_Container">
            <div className="Body_Content_Fifth">
              <div className="Body_Content_Fifth_Main">
                <p>How it works</p>
                <div style={{ display: "flex" }}>
                  <h4 className="Body_Fifth_Heding_One_Icon">
                    <div className="Body_Fifth_Anime_Box_TB">
                      <img alt="" srcSet={icon6} />
                    </div>
                  </h4>
                  <h4 className="Body_Fifth_Heding_Two_Icon">
                    <div className="Body_Fifth_Anime_Box_TB">
                      <img alt="" srcSet={icon15} />
                    </div>
                  </h4>
                </div>
                <div style={{ display: "flex" }}>
                  <h4 className="Body_Fifth_Heding_One">SignUp</h4>
                  <h4 className="Body_Fifth_Heding_Two">
                    Install and connect your cameras
                  </h4>
                </div>
                <div className="Body_Content_Fifth_Inner">
                  <div className="Fifth_Container_One"></div>
                  <div className="Fifth_Container_Two"></div>
                  <div className="Fifth_Container_Three"></div>
                </div>
                <div className="Body_Content_Fifth_Inner">
                  <div className="Fifth_Container_Four"></div>
                  <div className="Fifth_Container_Five"></div>
                  <div className="Fifth_Container_Six"></div>
                </div>
                <div style={{ display: "flex" }}>
                  <h4 className="Body_Fifth_Heding_Three">Pay</h4>
                  <h4 className="Body_Fifth_Heding_Four">
                    Congratulations, it’s done use it
                  </h4>
                </div>
                <div style={{ display: "flex" }}>
                  <h4 className="Body_Fifth_Heding_Three_Icon">
                    <div className="Body_Fifth_Anime_Box_BT">
                      <img alt="" srcSet={icon14} />
                    </div>
                  </h4>
                  <h4 className="Body_Fifth_Heding_Four_Icon">
                    <div className="Body_Fifth_Anime_Box_BT">
                      <img alt="" srcSet={icon16} />
                    </div>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Section">
        <div className="Back_2 ">
          <div className="Body_Container">
            <div className="Body_Content_Sixth">
              <div className="Body_Content_Sixth_Inner">
                <div className="Body_Content_Sixth_Heading">
                  <p>Application</p>
                </div>
                <div className="Body_Content_Sixth_Main">
                  <div className="Body_Content_Sixth_Main_One"></div>
                  <div className="Body_Content_Sixth_Main_Two">
                    <div className="Sixth_Inner_Content_One">
                      <div className="Sixth_Inner_Content_One_Image">
                        <img alt="" srcSet={icon7} />
                      </div>
                      <p>Small & Medium Business offices</p>
                    </div>
                    <div className="Sixth_Inner_Content_One">
                      <div className="Sixth_Inner_Content_One_Image">
                        <img alt="" srcSet={icon8} />
                      </div>
                      <p>Manufacturing Industries</p>
                    </div>
                  </div>
                  <div className="Body_Content_Sixth_Main_Two">
                    <div className="Sixth_Inner_Content_One">
                      <div className="Sixth_Inner_Content_One_Image">
                        <img alt="" srcSet={icon9} />
                      </div>
                      <p>Retail Stores</p>
                    </div>
                    <div className="Sixth_Inner_Content_One">
                      <div className="Sixth_Inner_Content_One_Image">
                        <img alt="" srcSet={icon10} />
                      </div>
                      <p>Government Ofﬁces</p>
                    </div>
                  </div>
                  <div className="Body_Content_Sixth_Main_Two">
                    <div className="Sixth_Inner_Content_One">
                      <div className="Sixth_Inner_Content_One_Image">
                        <img alt="" srcSet={icon11} />
                      </div>
                      <p>Hospitals</p>
                    </div>

                    <div className="Sixth_Inner_Content_One">
                      <div className="Sixth_Inner_Content_One_Image">
                        <img alt="" srcSet={icon12} />
                      </div>
                      <p>Schools & Colleges</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Formpage />
      <Footer />
    </div>
  );
};

export default FaceCoimbatore;
