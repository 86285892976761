import "./ANPR.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import logo1 from "../../assets/image/anpr_lpgo.svg";
import logo2 from "../../assets/image/anpr_banner.jpg";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import Header from "../../Header/Header";
import Productspage from "../../Productspage/Productspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const ANPR = () => {
  const [showForm, setForm] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [formValues.First_Name,
      formValues.Last_Name,
      formValues.Email,
      formValues.Phone_Number,
      formValues.Message,].some((field) => field?.trim() !== "")
    ) {
    setForm(false)
    setShowPopup(true);

    formValues.windowTitle = document.title;
    request
      .post(`${BaseURL}/Mail/ANPR`)
      .send(formValues)
      .set("Accept", "application/json")
      .then((response) => {
        console.log(response.body.message);
      })
      .catch((error) => {
        console.error("Error sending Email:", error);
      });

    setFormValues({
      First_Name: "",
      Last_Name: "",
      Email: "",
      Phone_Number: "",
      Message: "",
    });}
  };

  const handleCheckboxChange = () => {
    setIsVideo(!isVideo);
  };

  return (
    <div>
      <Helmet>
        <title>
          Automatic Number Plate Recognition (ANPR) | Vehicle Number Plate
          Camera | Parking Solution
        </title>
        <meta
          name="description"
          content="Safepro ANPR(Automatic Number Plate Recognition) offers numerous solutions such as  license plate detection, automated parking solution, regulated access control for vehicular movements, vehicular tracking solution.
          "
        />
        <meta
          name="keyword"
          content=" Automatic Number Plate Recognition, ANPR, Vehicle Number Plate Camera, Vehicle Number plate recognition, License plate detection, License plate recognition, Automated parking solution, Vehicle tracking solution, ANPR Camera.
          "
        />
        <link rel="canonical" href="https://www.safepro.tech/anpr.html" />
      </Helmet>
      <Header />
      <Productspage />
      <section className="Sec">
        <div className="Body_Section">
          <div className="Body_Section_parts">
            <div className="left_Body_Section_parts">
              <img src={logo1} alt="" />
              <p>Automated Number Plate Recognition & Beyond</p>
            </div>
            <div className="right_Body_Section_parts">
              <img src={logo2} alt="" />
              <div className="Body_Section_video">
                <input type="checkbox" onClick={handleCheckboxChange} />
                <p>Click here for the video</p>
              </div>
              {isVideo && (
                <div className="video">
                  <iframe
                    src="https://www.youtube.com/embed/l3Ys8UekYWQ?autoplay=1&&mute=1&playlist=l3Ys8UekYWQ&loop=1"
                    allow="autoplay"
                    title="Vigilar"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="section_2">
        <div className="section_2_Body_Section">
          <div className="section_2_width">
            <div className="section_2_content">
              <h1>ANPR</h1>
              <p>
                Safepro Opticsense® ANPR solution is designed and built on our
                neural compute platform keeping in mind not just Automated
                Number Plate Recognition, but solutions beyond this. Whilst we
                detect the license plate, we also offer numerous solutions like
                the automated parking solution, regulated access control for
                vehicular movements, vehicular tracking solution for theft &
                other criminal activities, vehicle profiling & cataloguing &
                more. With in-camera analytics, it’s much faster & efficient
                even in demanding situations. With highways being the lifeline
                of the country, it’s also one of the reasons of accidental
                deaths in the country. Safepro® Opticsense® ANPR & beyond
                solution is an edge AI camera with a host of features to
                monitor, analyse and manage highway traffic better. Capable of
                reading vehicle license plates at speeds of over 120kms, it also
                combines radar module integration for high accuracy. Over
                speeding, vehicle type, vehicle counting are some of the inbuilt
                edge analytics within the camera.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_left">
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>
                    Edge based analytics with OCR for accurate vehicle number
                    plate screening
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Radar based screening for high accuracy at speeds over
                    120kms
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Blackspots screening and data analysis</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Vehicular blacklisting and whitelisting</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Option to integrate with traffic gantries</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Integrated alerts for marked vehicles with location details
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Option for API access for integration with existing ITMS
                    applications
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Vehicle type and vehicle count options as enhanced features
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Inbuilt pulse sensor for downtime alerts</p>
                </div>
              </div>
            </div>
            <div className="mobile_section_3_right"></div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog} >
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e)
                            handleNumChangeM(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "80px" }}
        >
          <button
            className="Body_Content_Forth_Button_One"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default ANPR;
