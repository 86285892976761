import "./VideoAnalyticspage.css";
import Header from "../Header/Header";
import Formpage from "../Form/Form";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import icon1 from "../assets/image/anpr_lpgo.svg";
import icon2 from "../assets/image/video_ana_banner.jpg";
import image1 from "../assets/image/Face_Recognition.jpg";
import image2 from "../assets/image/Safety_Gear_Violation.jpg";
import image3 from "../assets/image/Retailytics.jpg";
import image4 from "../assets/image/Admetrics.jpg";
import image5 from "../assets/image/Object_Detection.jpg";
import image6 from "../assets/image/drive_alert_system.jpg";
import image7 from "../assets/image/thermography.jpg";
import image8 from "../assets/image/custom_made.jpg";
import image9 from "../assets/image/ITMS.jpg";
import image10 from "../assets/image/Time.jpg";
import image12 from "../assets/image/Products_brochure_arrow.svg";
import icon3 from "../assets/PNG/close.png";
import icon from "../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon4 from "../assets/Facesense_LandingPage_Img/Cancel.svg";
import { useForm } from "react-hook-form";
import request from "superagent";
import { BaseURL } from "../BaseURL";

const VideoAnalyticspage = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [hovered6, setHovered6] = useState(false);
  const [hovered7, setHovered7] = useState(false);
  const [hovered8, setHovered8] = useState(false);
  const [hovered9, setHovered9] = useState(false);
  let hoveredElement = null;
  if (hovered1) {
    hoveredElement = "hovered1";
  } else if (hovered2) {
    hoveredElement = "hovered2";
  } else if (hovered3) {
    hoveredElement = "hovered3";
  } else if (hovered4) {
    hoveredElement = "hovered4";
  } else if (hovered5) {
    hoveredElement = "hovered5";
  } else if (hovered6) {
    hoveredElement = "hovered6";
  } else if (hovered7) {
    hoveredElement = "hovered7";
  } else if (hovered8) {
    hoveredElement = "hovered8";
  } else if (hovered9) {
    hoveredElement = "hovered9";
  } else if (hovered) {
    hoveredElement = "hovered";
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const cursorClasses = `cursor ${hoveredElement ? hoveredElement : ""}`;

  const handleImageMouseEnter = () => {
    setHovered(true);
  };
  const handleImageMouseLeave = () => {
    setHovered(false);
  };
  const handleImageMouseEnter1 = () => {
    setHovered1(true);
  };
  const handleImageMouseLeave1 = () => {
    setHovered1(false);
  };
  const handleImageMouseEnter2 = () => {
    setHovered2(true);
  };
  const handleImageMouseLeave2 = () => {
    setHovered2(false);
  };
  const handleImageMouseEnter3 = () => {
    setHovered3(true);
  };
  const handleImageMouseLeave3 = () => {
    setHovered3(false);
  };
  const handleImageMouseEnter4 = () => {
    setHovered4(true);
  };
  const handleImageMouseLeave4 = () => {
    setHovered4(false);
  };
  const handleImageMouseEnter5 = () => {
    setHovered5(true);
  };
  const handleImageMouseLeave5 = () => {
    setHovered5(false);
  };
  const handleImageMouseEnter6 = () => {
    setHovered6(true);
  };
  const handleImageMouseLeave6 = () => {
    setHovered6(false);
  };
  const handleImageMouseEnter7 = () => {
    setHovered7(true);
  };
  const handleImageMouseLeave7 = () => {
    setHovered7(false);
  };
  const handleImageMouseEnter8 = () => {
    setHovered8(true);
  };
  const handleImageMouseLeave8 = () => {
    setHovered8(false);
  };
  const handleImageMouseEnter9 = () => {
    setHovered9(true);
  };
  const handleImageMouseLeave9 = () => {
    setHovered9(false);
  };
  const { register, handleSubmit, reset } = useForm();
  const [mob, setNum1] = useState("");
  const [showPortfolio, setPortfolio] = useState(false);
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setNum1(numericValue.slice(0, limit1));
  };
  const [showPopup, setShowPopup] = useState(false);

  const onSubmit = (data) => {
    if (
      [
        register.First_Name,
        register.Last_Name,
        register.Email,
        register.Phone_Number,
        register.Message,].some((field) => field?.trim() !== "")
    ) {
      setPortfolio(false)
      setShowPopup(true);
      reset();
      setShowPopup(true);
      request
        .post(`${BaseURL}/Mail/Analytics`)
        .send(data)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });
      reset();
      setNum1("");
    }
  };

  const handleClose = () => {
    setPortfolio(false);
  };
  function handleScroll() {
    const sections = [
      {
        containerSelector: ".section_2_box_2_img",
        yOffset: 400,
      },
      {
        containerSelector: ".section_2_box_3_img",
        yOffset: 730,
      },
      {
        containerSelector: ".section_2_box_6_img",
        yOffset: 1180,
      },
      {
        containerSelector: ".section_2_box_7_img",
        yOffset: 1470,
      },
      {
        containerSelector: ".section_2_box_10_img",
        yOffset: 1900,
      },
    ];

    sections.forEach(({ containerSelector, yOffset }) => {
      const imageContainers = document.querySelectorAll(containerSelector);

      imageContainers.forEach((container) => {
        const risingOffset = -window.pageYOffset * 0.3 + yOffset;
        container.style.transform = `translateY(${risingOffset}px)`;
      });
    });
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="VA">
      <Helmet>
        <title>
          Best Video Analytics Company Software Technology | Edge Video
          analytics System
        </title>
        <meta
          name="description"
          content="Safepro is world top leading edge video analytics software technology company offering best edge video intelligent enabled video surveillance, face recognition time attendance system, opticsense process automation etc.
          "
        />
        <meta
          name="keyword"
          content=" Video analytics software, Video analytics Technology, Edge Video Analytics Company, Top video  analytics, Video analytics software companies, Video analytics Technology, Video analytics. world leading video analytics company, Intelligent video analytics.
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/Video-analytics.html"
        />
      </Helmet>
      <Header />
      <section className="SECTION">
        <div className="Body_section">
          <div className="Body_Section_Parts">
            <div className="left_Body_Section_Parts">
              <img src={icon1} alt="" />
              <p>Video Analytics</p>
              <a href="/Video-analytics.html">Making sense of seeing</a>
            </div>
            <div className="right_Body_Section_part">
              <img src={icon2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <div className="SECTION_2">
        <div className="section_2_Body_Section">
          <div
            className={cursorClasses}
            style={{ left: `${position.x}px`, top: `${position.y}px` }}
          ></div>
          <div className="section_2_Width">
            <div className="section_2_first ">
              <div className="section_2_box_1 ">
                <a
                  href="/face-recognition-time-attendance-System.php"
                  onMouseEnter={handleImageMouseEnter}
                  onMouseLeave={handleImageMouseLeave}
                >
                  <div className="section_2_box_1_img ">
                    <img src={image1} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_2_box_2">
                <a href="/videoanalytics/ppedetection/pharma.html">
                  <div
                    className="section_2_box_2_img"
                    onMouseEnter={handleImageMouseEnter1}
                    onMouseLeave={handleImageMouseLeave1}
                  >
                    <img src={image2} alt="" />
                  </div>
                </a>
              </div>
            </div>

            <div className="section_2_secound">
              <div className="section_2_box_3">
                <a href="/cctv/retailstores/people-counting.html">
                  <div
                    className="section_2_box_3_img"
                    onMouseEnter={handleImageMouseEnter2}
                    onMouseLeave={handleImageMouseLeave2}
                  >
                    <img src={image3} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_2_box_4">
                <a href="/admetrics">
                  <div
                    className="section_2_box_4_img"
                    onMouseEnter={handleImageMouseEnter3}
                    onMouseLeave={handleImageMouseLeave3}
                  >
                    <img src={image4} alt="" />
                  </div>
                </a>
              </div>
            </div>

            <div className="section_2_first">
              <div className="section_2_box_5">
                <a href="/videoanalytics/object-detection.html">
                  <div
                    className="section_2_box_5_img"
                    onMouseEnter={handleImageMouseEnter4}
                    onMouseLeave={handleImageMouseLeave4}
                  >
                    <img src={image5} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_2_box_6">
                <a href="/videosurveillance/publictransport.html">
                  <div
                    className="section_2_box_6_img"
                    onMouseEnter={handleImageMouseEnter5}
                    onMouseLeave={handleImageMouseLeave5}
                  >
                    <img src={image6} alt="" />
                  </div>
                </a>
              </div>
            </div>

            <div className="section_2_secound">
              <div className="section_2_box_7">
                <a href="/temperature-tracking-camera/covid-19/thermography.html">
                  <div
                    className="section_2_box_7_img"
                    onMouseEnter={handleImageMouseEnter6}
                    onMouseLeave={handleImageMouseLeave6}
                  >
                    <img src={image7} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_2_box_8">
                <a href="/Custom-Made-Video-Analytics">
                  <div
                    className="section_2_box_8_img"
                    onMouseEnter={handleImageMouseEnter7}
                    onMouseLeave={handleImageMouseLeave7}
                  >
                    <img src={image8} alt="" />
                  </div>
                </a>
              </div>
            </div>

            <div className="section_2_first">
              <div className="section_2_box_9">
                <a href="/Intelligent-Traffic-Management-System">
                  <div
                    className="section_2_box_9_img"
                    onMouseEnter={handleImageMouseEnter8}
                    onMouseLeave={handleImageMouseLeave8}
                  >
                    <img src={image9} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_2_box_10">
                <a href="/videoanalytics/facerecognition/attendance-management-system-educational-institution.php">
                  <div
                    className="section_2_box_10_img"
                    onMouseEnter={handleImageMouseEnter9}
                    onMouseLeave={handleImageMouseLeave9}
                  >
                    <img src={image10} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="section_3_Width">
            <div className="section_3_first">
              <div className="section_3_box_1">
                <a href="/face-recognition-time-attendance-System.php">
                  <div className="section_3_box_1_img">
                    <img src={image1} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_3_box_2">
                <a href="/videoanalytics/ppedetection/pharma.html">
                  <div className="section_3_box_2_img">
                    <img src={image2} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="section_3_first">
              <div className="section_3_box_3">
                <a href="/cctv/retailstores/people-counting.html">
                  <div className="section_3_box_3_img">
                    <img src={image3} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_3_box_1">
                <a href="/admetrics">
                  <div className="section_3_box_1_img">
                    <img src={image4} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="section_3_first">
              <div className="section_3_box_1">
                <a href="/object-detection.html">
                  <div className="section_3_box_1_img">
                    <img src={image5} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_3_box_4">
                <a href="/videosurveillance/publictransport.html">
                  <div className="section_3_box_2_img">
                    <img src={image6} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="section_3_first">
              <div className="section_3_box_1">
                <a href="/Custom-Made-Video-Analytics">
                  <div className="section_3_box_2_img">
                    <img src={image7} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_3_box_4">
                <a href="/temperature-tracking-camera/covid-19/thermography.html">
                  <div className="section_3_box_2_img">
                    <img src={image8} alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div className="section_3_first">
              <div className="section_3_box_1">
                <a href="/Intelligent-Traffic-Management-System">
                  <div className="section_3_box_2_img">
                    <img src={image9} alt="" />
                  </div>
                </a>
              </div>
              <div className="section_3_box_4">
                <a href="/videoanalytics/facerecognition/attendance-management-system-educational-institution.php">
                  <div className="section_3_box_2_img">
                    <img src={image10} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="OurPortfolio">
        <div className="OurPortFolio_Block">
          <div className="Portfolio_data">
            <div className="PortFolio_Panel">
              <h1>Explore our Analytics portfolio!</h1>
            </div>
            <div
              className="PortFolio_Panel"
              onClick={() => {
                setPortfolio(true);
              }}
            >
              <h2>
                Video Analytics Brochure <img srcSet={image12} alt="" />{" "}
              </h2>
            </div>
          </div>
        </div>
      </div>
      {showPortfolio && (
        <>
          <div className="OurPortfolio_Form">
            <form className="Form_Container" onSubmit={handleSubmit(onSubmit)}>
              <div className="Close_Button">
                <img src={icon3} alt="Close" onClick={handleClose} />
              </div>
              <div className="FormHeading">
                <h1>Please fill this form</h1>
                <p>
                  We will share the Video Analytics Brochure through your email.
                </p>
              </div>
              <div className="Form_InputField_Container">
                <div className="InputField_Panel">
                  <input
                    type="text"
                    {...register("First_Name")}
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="InputField_Panel_A">
                  <input
                    type="text"
                    {...register("Last_Name")}
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div className="Form_InputField_Container">
                <div className="InputField_Panel">
                  <input
                    type="email"
                    {...register("Email")}
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="InputField_Panel_A">
                  <input
                    type="number"
                    {...register("Phone_Number")}
                    placeholder="Phone Number"
                    value={mob}
                    onChange={(event) => {
                      handleNumChangeM(event);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="Form_InputField_Container_B">
                <textarea {...register("Message")} placeholder="Message" required />
              </div>
              <div className="Form_InputField_Container_A">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </>
      )}
      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup" style={{ zIndex: "1000" }}>
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
              </h3>
            </span>
          </div>
        </>
      )}
      <Formpage />
      <Footer />
    </div>
  );
};

export default VideoAnalyticspage;
